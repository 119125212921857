import styled from 'styled-components'

const ProductDetailsStyle = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    margin: 60px 0;
`

export default ProductDetailsStyle