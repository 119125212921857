import styled from 'styled-components'

const ButtonStyle = styled.button`
    border-radius: 5px;
    border: 1px solid;
    border-top: ${({ theme }) => theme.colors.midLight };
    border-left: ${({ theme }) => theme.colors.midLight };
    background-color: ${({ theme }) => theme.colors.black };
    height: ${({ isBig }) => isBig ? '60px' : '36px' };
    width: ${({ isBig }) => isBig ? '220px' : '94px' };
    justify-self: center;
    text-align: center;
    line-height: ${({ isBig }) => isBig ? '60px' : '36px' };
    font-size: ${({ isBig }) => isBig ? '20px' : '16px' };
    text-decoration: none;
    color: ${({ theme }) => theme.colors.light }; 

    &:active {
        border: ${({ theme }) => theme.colors.light };
    }
`

export default ButtonStyle