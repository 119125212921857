import styled from 'styled-components'

const SubtitleStyle = styled.h3`
    font-family: ${({ theme }) => theme.typography.default};
    font-weight: 400;
    font-size: ${({ isSmall }) => isSmall ? '14px' : '25px' };
    text-align: center;
    /* color: ${({ theme }) => theme.colors.black}; */
    color: ${({ isSmall, theme }) => isSmall ? theme.colors.dark : theme.colors.black };
    margin-top: 15px;
`

export default SubtitleStyle
