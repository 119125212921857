import React from 'react'
import { Link } from "gatsby"
import FlexDiv from '../FlexDiv/FlexDiv'
import ButtonStyle from '../ButtonStyle/ButtonStyle'
import SubtitleStyle from '../SubtitleStyle/SubtitleStyle'
import ProductDetailsStyle from './ProductDetailsStyle/ProductDetailsStyle'

const ProductWrapper = ({title, subtitle, imageUrl, imageAlt, short, url}) => {
    
    return(
        <FlexDiv align='center'>
            <img height='250px' src={imageUrl} alt={imageAlt} />
            <ProductDetailsStyle>
                <SubtitleStyle>{title.toUpperCase()}</SubtitleStyle>
                <SubtitleStyle isSmall>{subtitle}</SubtitleStyle>
                <p>{short}</p>
                <ButtonStyle as={Link} to={url}>Więcej</ButtonStyle>
            </ProductDetailsStyle>
        </FlexDiv>
    )
}

export default ProductWrapper