import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import ProductWrapper from "../components/PorductWrapper/ProductWrapper"
import TitleStyle from "../components/TitleStyle/TitleStyle"
import Content from "../components/Content/Content"
import Seo from "../components/SEO/SEO"
// import ButtonStyle from "../components/ButtonStyle/ButtonStyle"
// import FlexDiv from "../components/FlexDiv/FlexDiv"

const IndexPage = ({ data }) => {

  return (
    <Layout>
    <Seo title="Home" />
      <Content>
        <TitleStyle>Nasze produkty</TitleStyle>

        {data.allMarkdownRemark.edges.map((product) => (
          <ProductWrapper key={product.node.id} title={product.node.frontmatter.title} subtitle={product.node.frontmatter.subtitle} imageUrl={product.node.frontmatter.imageUrl} imageAlt={product.node.frontmatter.title} short={product.node.frontmatter.short} description={product.node.html} url={product.node.frontmatter.slug} />
        ))}
        
        {/* <FlexDiv justify="flex-end">
          <ButtonStyle isBig>Zobacz wszytko</ButtonStyle>
        </FlexDiv> */}
      </Content>
        
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            slug
            short
            imageUrl
          }
          html
        }
      }
    }
  }
`

export default IndexPage
